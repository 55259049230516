/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function AusstellerHeader(
    props = {
        headers: [
            "Aussteller",
            "Branche",
            "Gesuchte Fachrichtung",
            "Auftrittstag",
        ],
    }
) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <>
            {props.headers.map((header, index) => {
                return (
                    <span
                        key={index}
                        style={{
                            ...headerStyle,
                        }}
                    >
                        {header}
                    </span>
                )
            })}
        </>
    )
}
AusstellerHeader.defaultProps = {
    headers: ["Aussteller", "Branche", "Gesuchte Fachrichtung", "Auftrittstag"],
}

const headerStyle = {
    fontSize: "large",
    fontWeight: "bold",
    color: "#007ec6",
    gridColumn: "span 1",
}
